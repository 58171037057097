import {FC, MouseEventHandler} from 'react'
import {GButton} from '../../Libs/Button/Button.component'

interface ModalFooterProps {
  handleSuccess?: MouseEventHandler
  handleClose?: MouseEventHandler
  positiveLabel?: string
  negativeLabel?: string
  disablePositive?: boolean
  hidePositive?: boolean
  loading?: boolean
  footNote?: string
  countingNote?: string
  formik?: any
  type?: any
}

interface PropsNote {
  countingNote?: string
  footNote?: string
}

const FootNoteElement: FC<PropsNote> = ({countingNote, footNote}) => {
  return <div className='flex'>
    {countingNote && 
      <div className='mr-2'>{countingNote}</div>
    }
    {footNote}
  </div>
}

const ModalFooter: FC<ModalFooterProps> = ({
  handleSuccess,
  handleClose,
  positiveLabel,
  negativeLabel,
  disablePositive,
  hidePositive = false,
  loading,
  countingNote,
  footNote,
}) => {
  const justifyContentStyle = footNote ? 'justify-between' : 'justify-end';
  return (
    <div className={`flex items-center w-full px-6 py-5 border-t border-neutral-30 ${justifyContentStyle}`}>
      <FootNoteElement countingNote={countingNote} footNote={footNote}/>
      <div>
      { negativeLabel && (
        <GButton
          onClick={handleClose}
          type='button'
          variant='OUTLINED'
          className='mr-4 min-w-[138px]'
          disabled={loading}
          data-testid='close-button'
        >
          {negativeLabel ?? 'Cancel'}
        </GButton>
        )}
      {!hidePositive && 
        <GButton
          onClick={handleSuccess}
          className='min-w-[138px]'
          type='button'
          loading={loading}
          disabled={loading || disablePositive}
          data-testid='success-button'
          >
          {positiveLabel ?? 'Leave'}
        </GButton>
      }
      </div>
    </div>
  )
}

export default ModalFooter
