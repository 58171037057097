import { FC, MouseEventHandler, useCallback, useState, useEffect } from 'react'
import { useDebounceEffect } from 'src/app/hooks/debounce-hook'
import { FormText, GButton } from '../Libs'
import QueryTable from '../Libs/Table/QueryTable/QueryTable'
import TableError from '../Libs/Table/TableError'
import clsx from 'clsx'
import CalendarModal from './ModalCollection/CalendarModal'
import TableFilter from './TableFilter'
interface Props {
  renderTable: any
  headers: any
  generatedForm?: any
  filterCount?: number
  formik?: any
  resetFilterFn?: any
  isShow?: any
  setIsShow?: any
  isShowCalendar?: any
  setIsShowCalendar?: any
  isSearch?: boolean
  isFilter?: boolean
  isPagination?: boolean
  filterCalendar?: boolean
  createButton?: boolean
  titleCalendar?: string
  placeholder?: string
  titleCreateButton?: string
  notFoundType?: 'TYPE_1' | 'TYPE_2'
  onResetFilterClick?: MouseEventHandler
  onCreateButton?: any
}

const BodyTableV2: FC<Props> = ({
  formik,
  generatedForm,
  resetFilterFn,
  renderTable,
  headers,
  isShow,
  setIsShow,
  isShowCalendar,
  setIsShowCalendar,
  filterCount = 0,
  isSearch = true,
  isFilter = true,
  filterCalendar = false,
  createButton = false,
  isPagination = true,
  titleCalendar,
  placeholder = 'Search',
  notFoundType = 'TYPE_1',
  titleCreateButton = 'Create',
  onResetFilterClick,
  onCreateButton,
}) => {
  const handleSearchFn = useDebounceEffect(
    useCallback(
      (search: string) => renderTable.setQuery((previus: any) => ({ ...previus, search })),
      [renderTable.setQuery]
    ),
    500
  )

  const [dateRangeFilter, setDateRangeFilter] = useState<any>()
  const [selectedDateFilter, setSelectedDateFilter] = useState<any>()

  useEffect(() => {
    if (dateRangeFilter !== undefined && dateRangeFilter.length === 2) {
      const startDateFilter = dateRangeFilter[0].format('YYYY-MM-DD')
      const endDateFilter = dateRangeFilter[1].format('YYYY-MM-DD')

      formik.setFieldValue('start_date', startDateFilter)
      formik.setFieldValue('end_date', endDateFilter)
    }
  }, [dateRangeFilter, formik.values])

  const handleResetCalendar = () => {
    setDateRangeFilter('')
    setSelectedDateFilter('')
    setIsShowCalendar(false)
    formik.setFieldValue('start_date', '')
    formik.setFieldValue('end_date', '')
    formik.handleSubmit()
  }

  const handleSubmitCalendar = () => {
    setSelectedDateFilter(dateRangeFilter)
    setIsShowCalendar(false)
    formik.handleSubmit()
  }

  return (
    <>
      {!renderTable.isError ? (
        <>
          {(!renderTable.isFetching || renderTable.state.query.search !== '') && (
            <div className='flex items-center justify-between mb-4'>

              <div className='flex'>
                {isSearch && (
                  <div className='md:min-w-[300px]'>
                    <FormText
                      placeholder={placeholder}
                      prependInnerIcon='IconSearch'
                      appendInnerIcon={renderTable.state.query.search ? 'IconClose' : undefined}
                      appendInnerClassname='text-neutral-60'
                      clickAppendInnerIcon={() => handleSearchFn('')}
                      onChange={(e) => handleSearchFn(e.target.value)}
                      name='search'
                    />
                  </div>
                )}

                {filterCalendar && (
                  <GButton
                    size='large'
                    variant='OUTLINED'
                    startIcon='IconCalendarRounded'
                    className={clsx('bg-netral border-primary hover:bg-primary-light-1 mx-4', { 'bg-primary-light-2 border-none': selectedDateFilter })}
                    onClick={() => setIsShowCalendar(!isShowCalendar)}
                  >
                    {selectedDateFilter ? (selectedDateFilter.toString().replace(',', ' - ')) : 'Select Date'}
                  </GButton>
                )}
                {isFilter && (
                  <TableFilter
                    filterCount={filterCount}
                    formik={formik}
                    generatedForm={generatedForm}
                    resetFilterFn={resetFilterFn}
                    isShow={isShow}
                    setIsShow={setIsShow} />
                )}
              </div>
              {createButton && (
                <GButton startIcon='IconAdd' onClick={onCreateButton}>
                  {titleCreateButton}
                </GButton>
              )}
            </div>
          )}

          <QueryTable
            render={renderTable}
            headers={headers}
            notFoundType={notFoundType}
            onResetFilterClick={onResetFilterClick}
            showPagination={isPagination}
          />

          <CalendarModal
            title={titleCalendar || 'Date Filter'}
            isShowCalendar={isShowCalendar}
            setIsShowCalendar={setIsShowCalendar}
            setSelectedDate={setSelectedDateFilter}
            value={selectedDateFilter}
            setValue={setDateRangeFilter}
            reset={handleResetCalendar}
            apply={handleSubmitCalendar}
          />
        </>
      ) : (
        <TableError />
      )}
    </>
  )
}

export default BodyTableV2
