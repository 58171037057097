import clsx from 'clsx'
import { components, ControlProps, GroupBase } from 'react-select'
import { FormCheckbox } from '../partials'
import { GroupHeading } from 'react-select/dist/declarations/src/components/Group'

export const Control = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>({
  children,
  isFocused,
  isDisabled,
  ...rest
}: ControlProps<Option, IsMulti, Group>) => (
  <components.Control
    className={clsx('shadow-none bg-neutral-10 min-h-[44px]', {
      focus: isFocused,
      disabled: isDisabled,
    })}
    isFocused
    isDisabled
    {...rest}
  >
    {children}
  </components.Control>
)

export const IndicatorSeparator = () => null

const Option = (props: any) => (
  <components.Option {...props}>
    <div className='flex flex-row items-center'>
      {(!!props.data?.image) && (
        <img
          className='object-cover w-[32px] h-[32px] rounded-full mr-3'
          src={props.data?.image}
          alt={props.label}
        />
      )}
      {props.label}
    </div>
  </components.Option>
);

const MultiOption = (props: any) => (
  <components.Option {...props}>
    <div className='flex flex-row items-center'>
      <div className='flex flex-row items-center flex-1'>
        {(!!props.data?.image) && (
          <img
            className='object-cover w-[32px] h-[32px] rounded-full mr-3'
            src={props.data?.image}
            alt={props.label}
          />
        )}
        {props.label}
      </div>
      <FormCheckbox
        variant='classic'
        checked={props.isSelected}
        className='ml-4 '
        height='min-h-0'
      />
    </div>
  </components.Option>
);


const handleHeaderClick = (id: any) => {
  const node = document.querySelector(`#${id}`)?.parentElement?.nextElementSibling;
  if (node) {
    const classes = node.classList;
    if (classes.contains("hidden")) {
      classes.remove("hidden");
    } else {
      node.classList.add("hidden");
    }
  };
}

const CustomGroupHeading = (props:any) => {
  return (
    <div
      className="group-heading-wrapper"
      onClick={() => handleHeaderClick(props.id)}
    >
      <components.GroupHeading {...props} /> 
    </div>
  );
};

export const NoOptionsMessage = (props: any) => {
  return (
    <components.NoOptionsMessage {...props}>
      <div className='font-medium text-start'>Result not found</div>
    </components.NoOptionsMessage>
  )
}

export interface LabelValueProps {
  label?: string
  value?: string
}

export interface IdNameProps {
  id?: string
  name?: string
}

export const CustomReactSelect = () => ({
  Control,
  Option,
  IndicatorSeparator,
  NoOptionsMessage,
  GroupHeading:CustomGroupHeading
})

export const CustomReactMultiSelect = {
  Control,
  Option: MultiOption,
  IndicatorSeparator,
  NoOptionsMessage,
}
