import { FC } from "react";
import { GButton } from "../Libs";
import FilterCard from "./FilterCard";
import clsx from "clsx";

interface TableFilterProps {
  filterCount: number
  formik: any
  generatedForm: any
  resetFilterFn: any
  isShow: any
  setIsShow: any
}

const TableFilter: FC<TableFilterProps> = ({ filterCount, formik, generatedForm, resetFilterFn, isShow, setIsShow }) => {
  return (
    <div className='relative'>
      <GButton
        size='large'
        variant='OUTLINED'
        startIcon='IconFilter'
        className={clsx('bg-netral border-primary hover:bg-primary-light-1', {
          'bg-primary-light-2': filterCount > 0
        })}
        onClick={() => setIsShow(!isShow)}
      >
        {filterCount > 1 && `${filterCount}`} Filter {filterCount > 0 && 'Applied'}
      </GButton>
      <FilterCard
        isShow={isShow}
        setIsShow={setIsShow}
        formik={formik}
        generatedForm={generatedForm}
        resetFilterFn={resetFilterFn}
      />
    </div>
  )
}

export default TableFilter