import { PaginateModel } from "src/app/models/api.types";
import { safeArray } from "src/app/utils/array-utils";
import { SelectAsyncAdditionalPayload } from "../MultiSelectAll";
import { FormSelect } from "./Select.component";
import { FormSelectAsyncProps } from "./SelectAsync.types";

const initAdditional = {
  limit: 10,
  page: 1
};

export function FormSelectAsync<T>({
  selectOption,
  fetchApi,
  payload,
  ...props
}: Readonly<FormSelectAsyncProps<T>>) {

  const getLoadOptions = async (
    search: string,
    _: unknown,
    additional: SelectAsyncAdditionalPayload = initAdditional
  ) => {
    try {

      const response = await fetchApi?.({ ...additional, search, ...payload });
      const {
        pagination,
        content
      } = response?.data?.response_output?.list || {};
      const {
        total_pages = 1
      } = pagination ?? {} as PaginateModel;
      const options = safeArray(content).map((_: any, __: number) => ({ ...selectOption?.(_, __) }));
      return {
        hasMore: additional?.page < total_pages,
        additional: { ...additional, page: additional.page + 1 },
        options
      };

    } catch (error) {
      return ({
        hasMore: false,
        options: []
      });
    }
  };

  return (
    <FormSelect
      {...props}
      loadOptions={getLoadOptions}
      asyncSelect={true}
    />
  );
}