import { useEffect, useState } from 'react';
import Select, { ClassNamesConfig, InputActionMeta, Props, StylesConfig } from 'react-select';
import { AsyncPaginate } from 'react-select-async-paginate';
import { FormError } from '../FormError';
import { FormText } from '../Text';
import { CustomMultiSelectAll } from './CustomMultiSelectAll.component';
import { Dropdown } from './Dropdown.component';
import { withMultiSelectProvider } from './MultiSelectAll.context';
import { useMultiSelectAllHook } from './MultiSelectAll.hooks';
import { FormMultiSelectAllProps } from './MultiSelectAll.types';

function _FormMultiSelectAll<T>({
  filterPlaceholder = 'Search...',
  withInputSearch,
  placeholder,
  error,
  touched,
  className,
  disabled,
  selectedValue,
  payload,
  noOptionsComponent,
  ...rest
}: Readonly<FormMultiSelectAllProps<T>>) {

  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const {
    action,
    state
  } = useMultiSelectAllHook({
    ...rest,
    selectedValue,
    payload
  });

  const handleInputChange = (value: string, actionMeta: InputActionMeta) => {
    if (actionMeta.action === 'input-change') {
      setInputValue(value);
    }
  };

  useEffect(
    () => {
      if (!isDropdownOpen) {
        setInputValue('');
      }
    },
    [isDropdownOpen]
  );

  const hardClassName: ClassNamesConfig<any, boolean, any> = {
    placeholder: (state) => 'text-neutral-60 font-medium',
    control: () =>
      `text-fs-9 rounded-xl font-medium text-neutral-100 border-none ${disabled && 'bg-neutral-40'}`,
    valueContainer: (state) => `min-h-0 px-4 ${state.isMulti ? 'py-0' : 'py-3'}`,
    input: () => 'm-0 p-0',
    dropdownIndicator: () => 'hidden',
    indicatorsContainer: () => 'min-h-[44px]',
    option: (state) =>
      `text-fs-9 rounded px-2 py-3 cursor-pointer ${state.isFocused ? 'text-primary' : 'text-neutral-80'
      }`,
    menuList: () => 'p-0 border-none max-h-[300px] mt-3 no-scrollbar',
    menu: () => 'shadow-none drop-shadow-[0_0_50px_rgba(33,37,41,0.13)]',
  }

  const hardStyles: StylesConfig<any, boolean, any> = {
    menu: () => ({ boxShadow: 'inset 0 1px 0 rgba(0, 0, 0, 0.1)' }),
    option: (base: any, state: any) => ({
      ...base,
      backgroundColor: state.isFocused ? '#EFF6FF' : '',
    }),
  };

  const selectProps: Props<any, boolean, any> = {
    controlShouldRenderValue: false,
    backspaceRemovesValue: false,
    onChange: action.handleChange,
    placeholder: filterPlaceholder,
    value: selectedValue,
    onInputChange: handleInputChange,
    inputValue: inputValue,
    isDisabled: disabled,
    isSearchable: true,
    isClearable: false,
    menuIsOpen: true,
    closeMenuOnSelect: false,
    hideSelectedOptions: false,
    options: rest.options,
    isMulti: true,
    classNames: hardClassName,
    styles: hardStyles,
    components: CustomMultiSelectAll({
      noOptionsComponent: noOptionsComponent?.({ setInputValue, inputValue }),
      withInputSearch,
    }),
  };

  return (
    <div data-testid='formselect-test' className={className}>
      <Dropdown
        onClose={() => setDropdownOpen(false)}
        isOpen={isDropdownOpen}
        renderButton={
          <FormText
            placeholder={placeholder}
            value={action.getInputValue()}
            readOnly={true}
            onClick={() => setDropdownOpen(true)}
            appendInnerIcon="IconCaret"
            appendInnerClassname={`transition-transform ${isDropdownOpen ? "-rotate-180" : "rotate-0"}`}
          />
        }
      >
        {(state.hasFetchApi) ? (
          <AsyncPaginate
            loadOptions={action.getLoadOptions as any}
            loadOptionsOnMenuOpen={false}
            debounceTimeout={300}
            additional={{ page: 1, limit: 10 }}
            cacheUniqs={[JSON.stringify(payload), inputValue]}
            {...selectProps}
          />
        ) : (
          <Select {...selectProps} />
        )}
      </Dropdown>

      {touched && error && <FormError>{error}</FormError>}
    </div>
  )
}

export const FormMultiSelectAll = withMultiSelectProvider(_FormMultiSelectAll);
