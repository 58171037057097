import axios, { AxiosError } from 'axios'
import dayjs from 'dayjs'
import { BaseResponse } from 'src/app/models/api.types'
import { hasArray, safeArray } from './array-utils'
import { cleanObject } from './object.utils'

export function getErrorMessage(
  error: AxiosError<BaseResponse<any>> | unknown,
  withProps: boolean = false,
  defaultValue: string | undefined = undefined
): string {
  let propsError: string | undefined
  try {
    if (withProps) {
      propsError = axios.isAxiosError(error)
        ? error?.response?.data?.response_output?.errors?.find(() => true)?.message ??
        defaultValue ??
        error?.response?.data?.response_schema?.response_message
        : undefined
    } else {
      propsError = axios.isAxiosError(error)
        ? error?.response?.data?.response_schema?.response_message
        : undefined
    }
  } catch (e) { }
  return propsError ?? String(error)
}

export async function convertBlob(error: AxiosError<BaseResponse<any>> | unknown) {
  if (axios.isAxiosError(error) && error.response?.data) {
    error.response.data = JSON.parse(await error.response?.data?.text())
  }
  return error
}

export interface ApiErrors {
  generic: string
  code: string
  detailed: Record<string, string>
}

export function getErrors(error: AxiosError<BaseResponse<any>> | unknown): ApiErrors {
  const detailed: Record<string, string> = {}
  const axiosResponse = axios.isAxiosError(error) ? error?.response : undefined
  try {
    if (axiosResponse)
      axiosResponse?.data?.response_output?.errors?.forEach((it: any) => {
        const message = it.message
        if (message) detailed[it.field] = message
      })
  } catch (e) { }
  return {
    generic: axiosResponse?.data?.response_schema?.response_message ?? String(error),
    code: axiosResponse?.data?.response_schema?.response_code ?? String(axiosResponse?.status),
    detailed,
  }
}

export interface ErrorFieldConfig {
  include?: string[]
  exclude?: string[]
  mapper?: Record<string, string>
}

export function handleErrorGeneric(
  error: AxiosError<BaseResponse<any>> | unknown,
  setFieldError: (fieldName: string, error: string) => any,
  fallback: (text: ApiErrors) => any,
  { include = [], exclude = [], mapper = {} }: ErrorFieldConfig = {}
) {
  const errors = getErrors(error)
  let entries = Object.entries(errors.detailed)
  if (include.length > 0) {
    entries = entries.filter((it) => include.indexOf(it[0]) > -1)
  }
  if (exclude.length > 0) {
    entries = entries.filter((it) => exclude.indexOf(it[0]) < 0)
  }
  if (entries.length > 0) {
    entries.forEach((it) => setFieldError(mapper[it[0]] ?? it[0], it[1]))
  } else {
    fallback(errors)
  }
}

function stringifyFormDataValue<T>(value: T) {
  if (typeof value === 'object') {
    return JSON.stringify(value);
  }
  return value;
}
export function convertFormData<T extends Record<string, any>>(payload: T, skipTypeCheckings: string[] = []) {

  const cleanPayload = cleanObject(payload);
  const formData = new FormData();
  Object.entries(cleanPayload).forEach(([key, value]) => {
    if (hasArray(value)) {
      value.forEach((item, index) => {
        formData.append(`${key}[${index}]`, stringifyFormDataValue(item));
      });
      return;
    }
    if (skipTypeCheckings?.includes(key)) {
      formData.append(key, value);
    } else {
      formData.append(key, stringifyFormDataValue(value));
    }
  });
  return formData;
}

export function reduceSelectOptions<T extends Record<string, any>>(data?: T[]): string[] {
  return safeArray(data).map((_) => _.value);
}

export function formatDatePayload(date?: any, format = 'YYYY-MM-DD') {

  return !date
    ? undefined
    : dayjs(date).format(format);
}
