import { createContext, createElement, FunctionComponent, ReactNode, useContext, useMemo, useState } from "react";
import { FormMultiSelectAllProps, MultiSelectAllOption } from "./MultiSelectAll.types";

interface MultiSelectProviderProps {
  selectAllOption?: MultiSelectAllOption;
  selectedValue?: MultiSelectAllOption[];
  children?: ReactNode;
}

interface MultiSelectContextValue {
  getSelectedOption: (option: MultiSelectAllOption) => boolean;
  setTotalOptions: (value: number) => void;
  selectAllOption?: MultiSelectAllOption;
  totalOptions: number;
}

const defaultSelectAllOption = {
  label: 'Select All',
  value: '__all'
}

const MultiSelectContext = createContext({} as MultiSelectContextValue);

function MultiSelectProvider<T extends MultiSelectProviderProps>({
  selectAllOption = {},
  selectedValue = [],
  children
}: T) {

  const [totalOptions, setTotalOptions] = useState(0);

  const getSelectedOption = (option: MultiSelectAllOption) => {
    // selectAllOption
    if (selectedValue.length >= totalOptions - 1 && option.value === selectAllOption.value) {
      return true;
    }
    if (selectedValue.some((_) => _.value === selectAllOption.value)) {
      if (option.value === selectAllOption.value) {
        return selectedValue.length < 2;
      }
      if (!!option.section) {
        const sectionValues = selectedValue.filter((_) => _.sectionGroup === option.section);
        return sectionValues.length < 1;
      }
      return !selectedValue.some((_) => _.value === option.value);
    }
    if (!!option.section) {
      const sectionValues = selectedValue.filter((_) => _.sectionGroup === option.section);
      return sectionValues.length === option.options?.length;
    }
    return selectedValue.some((_) => _.value === option.value);
  };

  const value = useMemo(
    () => ({
      getSelectedOption,
      setTotalOptions,
      selectAllOption,
      totalOptions
    }),
    [
      JSON.stringify(selectedValue),
      totalOptions
    ]
  );

  return (
    <MultiSelectContext.Provider value={value}>
      {children}
    </MultiSelectContext.Provider>
  );
}

export function withMultiSelectProvider(children: FunctionComponent<FormMultiSelectAllProps<any>>) {
  return (props: FormMultiSelectAllProps<any>) => {
    const _props = { selectAllOption: defaultSelectAllOption, ...props };
    return (
      <MultiSelectProvider {..._props}>
        {createElement(children, _props)}
      </MultiSelectProvider>
    );
  };
}

export function useMultiSelectContext() {
  const context = useContext(MultiSelectContext);
  return context;
}
