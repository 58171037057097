import {useMutation} from '@tanstack/react-query'
import {useFormik} from 'formik'
import {FC, useEffect} from 'react'
import {useHistory, useLocation} from 'react-router-dom'
import {FormLabel, FormPassword, GButton} from 'src/app/components/Libs'
import {useWebTitle} from 'src/app/hooks/title-hook'
import * as Yup from 'yup'
import {
  renewPasswordUser,
  verificationUserToken,
} from '../../../permissions/services/Permission.services'
import AuthScreens from '../../Screens'

const CreateNewPassword: FC = () => {
  const history = useHistory()
  let query = new URLSearchParams(useLocation().search)
  const token = query.get('token') as string
  const email = query.get('email') as string
  useWebTitle('Buat Password Baru')

  const resetSchema = Yup.object().shape({
    password: Yup.string()
      .min(8, 'Please make sure your password has 8 characters')
      .required('Enter your password'),
    confirm_password: Yup.string()
      .min(8, 'Please make sure your password has 8 characters')
      .required('Enter your confirmation password')
      .test('equal', 'Doesn’t match with new password.', function (v) {
        // Don't use arrow functions
        const ref = Yup.ref('password')
        return v === this.resolve(ref)
      }),
  })

  const initialValues = {
    password: '',
    confirm_password: '',
  }

  const submitFn = useMutation({
    mutationFn: (payload: typeof initialValues) => {
      const val = {
        password: payload.password,
        confirm_password: payload.confirm_password,
      }
      return renewPasswordUser({token: token,data: val})
    },
    onSuccess: () => {
      formik.setSubmitting(false)
      history.replace(AuthScreens.LOGIN_EMAIL.PATH)
    },
    onError: (e: any) => {
      e.response.data.response_output.errors?.map((item: { field: string; message: string | undefined }) => {
        formik.setFieldError(item.field, item.message)
      })
    },
  })

  const formik = useFormik({
    initialValues,
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: resetSchema,
    onSubmit: (values) => submitFn.mutate(values),
  })

  useEffect(() => {
    const payload = {
      token: token,
      email: email,
    }

    if (token && email) {
      verificationUserToken(payload)
        .then((res) => {
          const data = res?.data?.response_output?.detail?.status
          if (data === null) {
            history.replace(AuthScreens.LOGIN_EMAIL.PATH)
          }
        })
        .catch(() => {
          history.replace(`${AuthScreens.INVALID_TOKEN.BASE_PATH}/${email}`)
        })
    }
  }, [token, history])

  useEffect(() => {
    if (!token || !email) history.replace(AuthScreens.LOGIN_EMAIL.PATH)
  }, [history, token])

  if (!token) return null

  return (
    <div data-testid='create-new-password-page' className='w-full'>
      <div className='mb-6 text-left'>
        <div className='font-national2Condensed font-bold text-fs-4'>New Password</div>
        <div className='text-neutral-80'>Please enter your new password. New password must be different from previous used password.</div>
      </div>

      <form className='w-full' onSubmit={formik.handleSubmit} noValidate>
        <div className='mb-8'>
          <FormLabel required className='mb-2'>
            Password
          </FormLabel>
          <FormPassword
            {...formik.getFieldProps('password')}
            prependInnerIcon='IconLock'
            name='password'
            placeholder='Input your password'
            maxLength={50}
            error={formik.errors.password}
            touched={formik.touched.password}
          />
        </div>

        <div className='mb-8'>
          <FormLabel required className='mb-2'>
            Confirm New Password
          </FormLabel>
          <FormPassword
            {...formik.getFieldProps('confirm_password')}
            prependInnerIcon='IconLock'
            name='confirm_password'
            placeholder='Confirm New Password'
            maxLength={50}
            error={formik.errors.confirm_password}
            touched={formik.touched.confirm_password}
          />
        </div>

        <GButton type='submit' size='large' className='w-full' loading={submitFn.isLoading}>
          Confirm
        </GButton>
      </form>
    </div>
  )
}

export default CreateNewPassword
