import { Editor } from '@tinymce/tinymce-react'
import { FC, useEffect, useState } from 'react'
import { twMerge } from 'tailwind-merge'

export interface FormEditorProps {
  value: string
  onChange: (value: string, editor: any) => void
  placeholder: string
  error?: string
  touched?: boolean
  toolbar?: string
  initialEditorHeight?: number
  disableAutoResize?: boolean
}

const FormEditor: FC<FormEditorProps> = ({
  onChange,
  value,
  placeholder,
  touched,
  error,
  toolbar = `formatselect | bold italic link bullist numlist | indent outdent | image blockquote table media undo redo`,
  initialEditorHeight = 150,
  disableAutoResize
}) => {
  const [editorHeight, setEditorHeight] = useState(initialEditorHeight);

  useEffect(() => {
    const lineCount = value.split('\n').length;
    if (!disableAutoResize) {
      if (lineCount > 5) {
        setEditorHeight(340);
      } else {
        setEditorHeight(initialEditorHeight);
      }
    }
  }, [disableAutoResize, value]);
  
  return (
    <div>
      <div
        data-testid='formeditor-test'
        className={twMerge('w-full rounded-lg resize-y overflow-auto', error && touched && 'border border-danger')}
        style={{ height: editorHeight, minHeight: editorHeight }}
      >
        <Editor
          value={value}
          onEditorChange={onChange}
          init={{
            menubar: false,
            resize: true,
            placeholder: `${placeholder}`,
            width: "100%",
            height: "100%",
            plugins: [
              'advlist autolink lists link image charmap print preview anchor',
              'searchreplace visualblocks code fullscreen',
              'insertdatetime media table paste code help wordcount',
            ],
            toolbar
          }}
          tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
          key={editorHeight}
        />
      </div>
      {touched && error && <div className='mt-2 font-medium text-danger text-fs-10'>{error}</div>}
    </div>
  )
}

export { FormEditor }

