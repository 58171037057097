import { UseMutationOptions, useMutation } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import { useCallback } from "react";

import { BaseResponse, Response } from "../models/api.types";

interface OptionsParams<TVariables, TContext> extends UseMutationOptions<AxiosResponse<Blob>, AxiosError<BaseResponse<Response<any>>>, TVariables, TContext> {
  filename?: string;
}

export function useFileDownload<
  TVariables = any,
  TContext = unknown
>(
  options: OptionsParams<TVariables, TContext>
) {

  const mutation = useMutation(options);

  const download = useCallback(
    async (filename?: string, params?: TVariables) => {
      try {

        const {
          headers,
          config,
          data,
        } = await mutation.mutateAsync(params ?? {} as TVariables);
        const contentType: string = headers['content-type'] ?? headers['Content-Type'];
        const blob = contentType.startsWith('text/') ? new Blob([data], { type: contentType }) : data
        const objectUrl = URL.createObjectURL(blob);

        const linkEl = document.createElement('a');
        linkEl.href = objectUrl;
        const defaultFilename = config.url?.split('/').pop();
        const downloadName = filename ?? options.filename ?? defaultFilename ?? 'name';
        linkEl.setAttribute('download', downloadName);
        document.body.appendChild(linkEl);
        linkEl.click();

        document.body.removeChild(linkEl);
        URL.revokeObjectURL(objectUrl);

        return Promise.resolve();

      } catch (error) {
        return Promise.reject(error);
      }
    },
    []
  );

  return {
    ...mutation,
    download
  };
}
