import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { GIconButton, GeneratedFormProps } from 'src/app/components/Libs'
import { TableHeaders } from 'src/app/components/Libs/Table/GTable'
import HandleStatus from 'src/app/components/Widgets/HandleStatus'
import { usePermissions } from 'src/app/hooks/permissions-hook'
import { RootState } from 'src/setup'
import PromotionScreens from '../../Screens'
import { BannerModel } from '../../models/Banner.model'
import { Admin } from 'src/app/modules/permissions/models/Admin.model'
import { getModuleName } from 'src/app/utils/router-helpers-utils'
import { useLocation } from 'react-router-dom'
import { SectionBannerModel } from '../../models/SectionBanner.model'
import { formatDateWib } from 'src/app/utils/input-utils'
import HandleDateTime from 'src/app/components/Widgets/HandleDateTime'

export const FilterBanner = {
  statuses: [] as string[],
}

export function useTableBanner(state: any, generatedStatus?: any, data?: any, maxSequences: number = 0) {
  const user: Admin = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as Admin
  const { hasAccess } = usePermissions()
  const url = useLocation();
  const moduleName = getModuleName(url.pathname) || 'banner';

  const [deleteModal, setDeleteModal] = useState<boolean>(false)
  const [selected, setSelected] = useState<BannerModel | SectionBannerModel>()
  const [selectedRows, setSelectedRows] = useState<[]>([]);
  const [listBannerActive, setListBannerActive] = useState<any[]>([]);

  const urlDetailBanner = moduleName === 'banner' ? `${PromotionScreens.MAIN_BANNER.PATH}/detail` : `${PromotionScreens.SECTION_BANNER.PATH}/detail`
  const urlEditBanner = moduleName === 'banner' ? `${PromotionScreens.MAIN_BANNER.PATH}/edit` : `${PromotionScreens.SECTION_BANNER.PATH}/edit`

  useEffect(() => {
    if (data && data.length > 0) {
      const newListBannerActive = data.map((row: any) => row.is_active ? row.id : null).filter((row: null) => row !== null);
      setListBannerActive(newListBannerActive);
      setSelectedRows(newListBannerActive);
    }
  }, [data]);

  const generatedForm: GeneratedFormProps[] = useMemo(
    () => [
      {
        title: 'Status',
        value: generatedStatus
      },
    ],
    [generatedStatus]
  )

  const headers: TableHeaders<BannerModel>[] = useMemo(
    () => [
      {
        value: 'number',
        headerName: 'NO',
        width: 60,
        renderCell: (params, index) => (state.page - 1) * (state?.limit || 10) + index + 1,
      },
      {
        value: 'image_url',
        headerName: 'BANNER',
        sort: false,
        width: 150,
        renderCell: (params) => {
          return <img className='h-10' src={params.image_url} alt='Image banner' />
        }
      },
      {
        value: 'name',
        headerName: 'BANNER NAME',
        sort: true,
        width: 250,
      },
      { value: 'created_by', headerName: 'CREATED BY', width: 150 },
      { value: 'updated_by', headerName: 'UPDATED BY', width: 150 },
      {
        value: 'start_date',
        headerName: 'START DATE',
        sort: true,
        renderCell: (params) => <HandleDateTime data={params?.start_date} />,
        width: 140,
      },
      {
        value: 'end_date',
        headerName: 'END DATE',
        sort: true,
        renderCell: (params) => <HandleDateTime data={params?.end_date} />,
        width: 140,
      },
      {
        value: 'is_active',
        headerName: 'STATUS',
        renderCell: (params) => <HandleStatus value={params?.status?.toUpperCase()} />,
      },
      {
        value: 'action',
        headerName: 'ACTION',
        className: 'text-end sticky xxl:static right-0 bg-white dark:bg-inverse-primary-light-2',
        renderCell: (params) => {
          return (
            <div className='flex justify-start gap-2'>
              {hasAccess('main-banner.delete') && (
                <GIconButton
                  data-tooltip-id='delete-tooltip'
                  colors='warning'
                  onClick={() => {
                    setDeleteModal(true)
                    setSelected(params)
                  }}
                  icon='IconRemove'
                />
              )}
              {hasAccess('main-banner.view-detail') && (
                <GIconButton
                  data-tooltip-id='view-tooltip'
                  colors='secondary'
                  href={`${urlDetailBanner}/${params?.id}`}
                  icon='IconView'
                />
              )}
              {hasAccess('main-banner.update') && (
                <GIconButton
                  data-tooltip-id='edit-tooltip'
                  colors='info'
                  href={`${urlEditBanner}/${params?.id}`}
                  icon='IconEdit'
                />
              )}
            </div>
          )
        },
      },
    ],
    [state.limit, state.page]
  )
  const headersFeatured: TableHeaders<BannerModel>[] = useMemo(
    () => [
      {
        value: 'number',
        headerName: 'NO',
        width: 50,
        renderCell: (params, index) => (state.page - 1) * (state?.limit || 10) + index + 1,
      },
      {
        value: 'image_url',
        headerName: 'BANNER',
        renderCell: (params, index) => <img className='h-10' src={params.image_url} alt='Image banner' />,
        sort: false,
        width: 100
      },
      {
        value: 'name',
        headerName: 'BANNER NAME',
        sort: true,
        width: 480,
      },
      {
        value: 'start_date',
        headerName: 'START DATE',
        sort: true,
        renderCell: (params) => <HandleDateTime data={params.start_date} />,
        width: 130,
      },
      {
        value: 'end_date',
        headerName: 'END DATE',
        sort: true,
        renderCell: (params) => <HandleDateTime data={params.end_date} />,
        width: 130,
      },
      {
        value: 'action',
        headerName: 'ACTION',
        width: 90,
        className: 'dark:bg-inverse-primary-light-2',
        renderCell: (params) => {
          return (
            <div className='flex justify-start gap-2'>
              {hasAccess('main-banner.update') && (
                <input
                  className='disabled:opacity-30'
                  type='checkbox'
                  checked={listBannerActive.includes(params.id)}
                  onChange={() => { handleCheckboxChange(params.id) }}
                  disabled={false}
                />
              )}
            </div>
          )
        },
      },
    ],
    [state.limit, state.page, maxSequences, listBannerActive]
  )

  const headersSectionBanner: TableHeaders<SectionBannerModel>[] = useMemo(
    () => [
      {
        value: 'number',
        headerName: 'NO',
        width: 50,
        renderCell: (params, index) => (state.page - 1) * (state?.limit || 10) + index + 1,
      },
      {
        value: 'name',
        headerName: 'SECTION BANNER NAME',
        width: 500,
      },
      {
        value: 'total_content',
        headerName: 'TOTAL CONTENT',
        width: 200,
      },
      {
        value: 'start_date',
        headerName: 'START DATE',
        renderCell: (params) => formatDateWib(params.start_date),
        width: 150,
      },
      {
        value: 'end_date',
        headerName: 'END DATE',
        renderCell: (params) => formatDateWib(params.end_date),
        width: 150,
      },
      {
        value: 'is_active',
        headerName: 'STATUS',
        renderCell: (params) => <HandleStatus value={params?.status?.toUpperCase()} />,
      },
      {
        value: 'action',
        headerName: 'ACTION',
        className: 'text-end sticky xxl:static right-0 bg-white dark:bg-inverse-primary-light-2',
        renderCell: (params) => {
          return (
            <div className='flex justify-start gap-2'>
              {hasAccess('main-banner.delete') && (
                <GIconButton
                  data-tooltip-id='delete-tooltip'
                  colors='warning'
                  onClick={() => {
                    setDeleteModal(true)
                    setSelected(params)
                  }}
                  icon='IconRemove'
                />
              )}
              {hasAccess('main-banner.view-detail') && (
                <GIconButton
                  data-tooltip-id='view-tooltip'
                  colors='secondary'
                  href={`${urlDetailBanner}/${params?.id}`}
                  icon='IconView'
                />
              )}
              {hasAccess('main-banner.update') && (
                <GIconButton
                  data-tooltip-id='edit-tooltip'
                  colors='info'
                  href={`${urlEditBanner}/${params?.id}`}
                  icon='IconEdit'
                />
              )}
            </div>
          )
        },
      },
    ],
    [state.limit, state.page]
  )

  const handleCheckboxChange = (id: any) => {
    const index = listBannerActive.indexOf(id);
    const updatedList: any = [...listBannerActive];
    if (listBannerActive.length < maxSequences || index !== -1) {
      if (index === -1) {
        updatedList.push(id);
      } else {
        updatedList.splice(index, 1);
      }
      setListBannerActive(updatedList);
      setSelectedRows(updatedList);
    }
  };



  return { headers, headersSectionBanner, headersFeatured, deleteModal, setDeleteModal, selected, setSelected, generatedForm, selectedRows, listBannerActive }
}
