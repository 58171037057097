import { defaultBreadcrumbs, generateBreadcrumb } from 'src/app/models/screen-prop'

const GeneralConfigScreens = {
  GENERAL_CONFIG: {
    PATH: '/general-config',
    TITLE: 'General Config',
    TITLE_ALT: 'General Config',
    PERMISSION: 'general-config.get-list',
  },
  FAQ: {
    PATH: '/general-config/faq',
    HEADER_NAME: 'FAQ Setting',
    TITLE: 'FAQ Settings',
    TITLE_ALT: 'General Config',
    PERMISSION: 'faq.get-list',
    breadcrumbs: defaultBreadcrumbs,
  },
  FAQ_LIST: {
    PATH: '/general-config/faq/list',
    HEADER_NAME: 'List FAQ',
    TITLE: 'General Configs',
    TITLE_ALT: 'Frequently Asked Questions Settings',
    PERMISSION: 'faq.get-list',
    breadcrumbs: defaultBreadcrumbs,
  },
  FAQ_CREATE: {
    PATH: '/general-config/faq/list/create',
    HEADER_NAME: 'General Configs',
    TITLE: 'FAQ Settings',
    TITLE_ALT: 'Create Frequently Asked Questions',
    PERMISSION: 'faq.create',
    breadcrumbs: defaultBreadcrumbs,
  },
  FAQ_DETAIL: {
    PATH: '/general-config/faq/list/detail/:id',
    HEADER_NAME: 'General Configs',
    TITLE: 'FAQ Settings',
    TITLE_ALT: 'Detail Frequently Asked Questions',
    PERMISSION: 'faq.view-detail',
    buildPath: (id: string) => `/general-config/faq/list/detail/${id}`,
    breadcrumbs: defaultBreadcrumbs,
  },
  EDIT_FAQ: {
    PATH: '/general-config/faq/list/edit/:id',
    HEADER_NAME: 'General Configs',
    TITLE: 'FAQ Settings',
    TITLE_ALT: 'Edit Frequently Asked Questions',
    PERMISSION: 'faq.update',
    buildPath: (id: string) => `/general-config/faq/list/edit/${id}`,
    breadcrumbs: defaultBreadcrumbs,
  },
  TNC: {
    PATH: '/general-config/tnc',
    HEADER_NAME: 'Terms & Conditions Setting',
    TITLE: 'Terms & Conditions Setting',
    TITLE_ALT: 'Terms & Conditions Setting',
    PERMISSION: 'tnc.get-list',
    breadcrumbs: defaultBreadcrumbs,
  },
  EDIT_TNC: {
    PATH: '/general-config/tnc/edit',
    HEADER_NAME: 'Edit Terms & Conditions',
    TITLE: 'Edit Terms & Conditions',
    TITLE_ALT: 'Edit Terms & Conditions',
    PERMISSION: 'tnc.update',
    breadcrumbs: defaultBreadcrumbs,
  },
  PRIVACY_POLICY: {
    PATH: '/general-config/privacy-policy',
    HEADER_NAME: 'Privacy Policy',
    TITLE: 'Privacy Policy',
    TITLE_ALT: 'Privacy Policy',
    PERMISSION: 'privacy-policy.get-list',
    breadcrumbs: defaultBreadcrumbs,
  },
  EDIT_PRIVACY_POLICY: {
    PATH: '/general-config/privacy-policy/edit',
    HEADER_NAME: 'Edit Privacy Policy',
    TITLE: 'Edit Privacy Policy',
    TITLE_ALT: 'Edit Privacy Policy',
    PERMISSION: 'privacy-policy.update',
    breadcrumbs: defaultBreadcrumbs,
  },

  PUSH_NOTOFICATION: {
    PATH: '/general-config/push-notification',
    HEADER_NAME: 'Push Notification Settings',
    TITLE: 'Push Notification Settings',
    TITLE_ALT: 'Notification List',
    PERMISSION: 'members.create',
    breadcrumbs: defaultBreadcrumbs,
  },
  PUSH_NOTOFICATION_LIST: {
    PATH: '/general-config/push-notification',
    HEADER_NAME: 'Notification List',
    TITLE: 'Notification List',
    TITLE_ALT: 'Notification List',
    PERMISSION: 'members.create',
    breadcrumbs: defaultBreadcrumbs,
  },
  PUSH_NOTOFICATION_ADD: {
    PATH: '/general-config/push-notification/add',
    HEADER_NAME: 'Create New Notification',
    TITLE: 'Create New Notification',
    TITLE_ALT: 'Create New Notification',
    PERMISSION: 'members.create',
    breadcrumbs: defaultBreadcrumbs,
  },
  PUSH_NOTOFICATION_DETAIL: {
    PATH: '/general-config/push-notification/detail/:id',
    HEADER_NAME: 'Detail Notification',
    TITLE: 'Detail Notification',
    TITLE_ALT: 'Detail Notification',
    PERMISSION: 'members.create',
    breadcrumbs: defaultBreadcrumbs,
  },
  PUSH_NOTOFICATION_EDIT: {
    PATH: '/general-config/push-notification/edit/:id',
    HEADER_NAME: 'Edit Notification',
    TITLE: 'Edit Notification',
    TITLE_ALT: 'Edit Notification',
    PERMISSION: 'members.create',
    breadcrumbs: defaultBreadcrumbs,
  },

  VERSION_MANAGEMENT: {
    PATH: '/general-config/version-management',
    HEADER_NAME: 'Version History',
    TITLE: 'Version Management',
    TITLE_ALT: 'Version Management',
    PERMISSION: 'version-management.get-list',
    breadcrumbs: defaultBreadcrumbs,
  },
  VERSION_CREATE: {
    PATH: '/general-config/version-management/update/recommended-version',
    HEADER_NAME: 'General Configs',
    TITLE: 'Recommended Version',
    TITLE_ALT: 'Version Management',
    PERMISSION: 'version.create',
    breadcrumbs: defaultBreadcrumbs,
  },
  VERSION_DETAIL: {
    PATH: '/general-config/version-management/detail/:id',
    HEADER_NAME: 'General Configs',
    TITLE: 'Detail Version',
    TITLE_ALT: 'Version Management',
    PERMISSION: 'version.view-detail',
    buildPath: (id: string) => `/general-config/version-management/detail/${id}`,
    breadcrumbs: defaultBreadcrumbs,
  },
  VERSION_EDIT: {
    PATH: '/general-config/version-management/update/:id',
    PATH_MINIMUM: '/general-config/version-management/update/minimum-version',
    PATH_RECOMMENDED: '/general-config/version-management/update/recomended-version',
    HEADER_NAME: 'General Configs',
    TITLE: 'Minimum Version',
    TITLE_ALT: 'Version Management',
    PERMISSION: 'version.update',
    buildPath: (id: string) => `/general-config/version-management/update/${id}`,
    breadcrumbs: defaultBreadcrumbs,
  },
}

const listBreadcrumbs = generateBreadcrumb([GeneralConfigScreens.GENERAL_CONFIG])

GeneralConfigScreens.FAQ.breadcrumbs = () => listBreadcrumbs
GeneralConfigScreens.TNC.breadcrumbs = () => listBreadcrumbs
GeneralConfigScreens.EDIT_FAQ.breadcrumbs = () => listBreadcrumbs
GeneralConfigScreens.EDIT_TNC.breadcrumbs = () => listBreadcrumbs
GeneralConfigScreens.PRIVACY_POLICY.breadcrumbs = () => listBreadcrumbs
GeneralConfigScreens.EDIT_PRIVACY_POLICY.breadcrumbs = () => listBreadcrumbs

export default GeneralConfigScreens
