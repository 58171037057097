import {defaultBreadcrumbs, generateBreadcrumb} from 'src/app/models/screen-prop'

const SplashscreenScreens = {
  SPLASHSCREEN: {
    PATH: '/general-config/splash-screen/list',
    HEADER_NAME: 'Splash Screen',
    TITLE: 'General Config',
    TITLE_ALT: 'Splash Screen',
    breadcrumbs: defaultBreadcrumbs,
  },
  DETAIL_SPLASHSCREEN: {
    PATH: '/general-config/splash-screen/list/detail/:id',
    HEADER_NAME: 'Detail Splash Screen',
    TITLE: 'General Config',
    TITLE_ALT: 'Splash Screen',
    buildPath: (id: string) => `/general-config/splash-screen/list/detail/${id}`,
    breadcrumbs: defaultBreadcrumbs,
  },
  EDIT_SPLASHSCREEN: {
    PATH: '/general-config/splash-screen/list/edit/:id',
    HEADER_NAME: 'Edit Splash Screen',
    TITLE: 'General Config',
    TITLE_ALT: 'Splash Screen',
    buildPath: (id: string) => `/general-config/splash-screen/list/edit/${id}`,
    breadcrumbs: defaultBreadcrumbs,
  },
  CREATE_SPLASHSCREEN: {
    PATH: '/general-config/splash-screen/list/create/',
    HEADER_NAME: 'Create Splash Screen',
    TITLE: 'General Config',
    TITLE_ALT: 'Splash Screen',
    breadcrumbs: defaultBreadcrumbs,
  },
}

const splashscreenBreadcrumbs = generateBreadcrumb([SplashscreenScreens.SPLASHSCREEN])

SplashscreenScreens.EDIT_SPLASHSCREEN.breadcrumbs = () => splashscreenBreadcrumbs

export default SplashscreenScreens