import clsx from 'clsx'
import { FormikProps } from 'formik'
import { FC } from 'react'
import { FormBuilder, GButton, GModal, GeneratedFormProps } from '../Libs'
import GIcon from '../Libs/Icon/GIcon'

interface FilterCardProps {
  title?: string
  size?: any
  variant?: any
  maxHeight?: boolean
  isShow: boolean
  setIsShow: Function
  formik: FormikProps<any>
  generatedForm: GeneratedFormProps[]
  resetFilterFn: () => void
  position?: string
  textSuccessButton?: string
  buttonWidth?: string
  buttonJustify?: string
}

const FilterCard: FC<FilterCardProps> = ({ title = 'Filter', size = 'w-full', maxHeight = true, variant = 'FULL', isShow, setIsShow, formik, generatedForm, resetFilterFn, textSuccessButton = 'Apply', buttonWidth = 'w-full', buttonJustify = 'justify-between' }) => {
  return (
    <GModal show={isShow} onHide={() => setIsShow(false)} className={size}>
      <form noValidate onSubmit={formik.handleSubmit} data-testid='filter-form'>
        < div className='w-full flex justify-between items-center px-4 py-3 gap-4 border-b border-neutral-30' >
          <div className='font-bold text-fs-7'>{title}</div>
          <div><GIcon className='h-6 cursor-pointer' onClick={() => setIsShow(false)} icon='IconClose'></GIcon></div>
        </div>
        <div className={clsx('px-6 py-4', { 'max-h-[374px] overflow-y-auto': maxHeight })}>
          <FormBuilder formik={formik} generated={generatedForm} variant={variant} />
        </div>
        <div className='px-6 py-4 border-t border-neutral-30'>
          <div className={clsx('w-full flex items-center gap-4', {
            'justify-between': !buttonWidth,
            [buttonJustify]: buttonJustify
          })}>
            <GButton
              type='button'
              size='small'
              className={buttonWidth || 'w-full'}
              variant='OUTLINED'
              disabled={!formik.dirty && !formik.isSubmitting}
              onClick={resetFilterFn}
              data-testid='reset-filter-button'
            >
              Reset
            </GButton>
            <GButton
              type='submit'
              size='small'
              className={buttonWidth || 'w-full'}
              disabled={!formik.dirty}
              data-testid='apply-filter-button'
            >
              {textSuccessButton}
            </GButton>
          </div>
        </div>
      </form>
    </GModal>
  )
}

export default FilterCard
