const DashboardScreens = {
  DASHBOARD: {
    PATH: '/dashboard',
    TITLE: 'Dashboard',
    TITLE_ALT: 'Dashboard',
    PERMISSION: 'dashboard.view',
  },
}

export default DashboardScreens
