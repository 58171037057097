import { FC, useEffect, useState } from 'react'
import Select, { ClassNamesConfig, SelectComponentsConfig } from 'react-select'
import { AsyncPaginate } from 'react-select-async-paginate'
import { CustomReactSelect } from '../../Custom/CustomReactSelect'
import { FormError } from '../FormError'
import { FormSelectProps, SelectOption } from './Select.types'

export const FormSelect: FC<FormSelectProps> = ({
  options,
  placeholder,
  error,
  touched,
  defaultValue,
  className,
  changedValue,
  disabled,
  selectedValue,
  asyncSelect,
  loadOptions,
  isOptionDisabled
}) => {
  const [val, setVal] = useState<SelectOption | SelectOption[] | ''>('')

  const onChange = (v: SelectOption) => {
    if (changedValue) {
      changedValue(v)
      setVal(v)
    }
  }

  useEffect(() => {
    if (!asyncSelect) {
      if (selectedValue === '' || selectedValue === undefined) {
        setVal('')
      } else {
        const payload = options?.find((pay) => pay.value === selectedValue)
        setVal({ value: selectedValue, label: payload?.label })
      }
    }
  }, [selectedValue, options, asyncSelect])

  useEffect(() => {
    if (asyncSelect && defaultValue) {
      if (!defaultValue?.label) {
        setVal('');
      } else {
        setVal({ value: defaultValue?.value, label: defaultValue?.label })
      }
    }
  }, [defaultValue, asyncSelect])

  const hardClassName: ClassNamesConfig<any, false, any> = {
    placeholder: () => 'text-[#94A3B8] font-medium',
    control: () =>
      `text-fs-9 font-medium text-neutral-100 rounded-xl ${touched && error ? 'border-danger' : 'border-none'
      } ${disabled && 'bg-[#CBD5E1]'}`,
    valueContainer: (state) => `min-h-0 px-4 ${state.isMulti ? 'py-0 h-11' : 'py-3'}`,
    input: () => 'm-0 p-0',
    dropdownIndicator: (state) =>
      `transition-transform duration-150 ${state.selectProps.menuIsOpen ? 'rotate-180' : 'rotate-0'
      }`,
    indicatorsContainer: () => 'max-h-[44px]',
    option: (state) =>
      `text-fs-9 rounded px-2 py-3 cursor-pointer ${state.isFocused ? 'text-primary' : 'text-neutral-80'
      }`,
    menuList: () => 'p-3 border-none max-h-[300px]',
    menu: () => 'shadow-none drop-shadow-[0_0_50px_rgba(33,37,41,0.13)]',
  }

  return (
    <div data-testid='formselect-test' className={className}>
      {asyncSelect ? (
        <AsyncPaginate
          data-testid='formselect-test-value'
          defaultOptions
          placeholder={placeholder}
          onChange={onChange}
          loadOptions={loadOptions}
          debounceTimeout={500}
          components={CustomReactSelect() as SelectComponentsConfig<any, false, any>}
          value={val}
          defaultValue={defaultValue}
          isDisabled={disabled}
          styles={{
            option: (base, state) => ({
              ...base,
              backgroundColor: state.isFocused ? '#EFF6FF' : '',
            }),
          }}
          classNames={hardClassName}
          isSearchable
        />
      ) : (
        <Select
          data-testid='formselect-test-value'
          components={CustomReactSelect() as SelectComponentsConfig<any, false, any>}
          isOptionDisabled={!isOptionDisabled ? undefined : (_, __) => isOptionDisabled(_, __)}
          options={options}
          onChange={onChange}
          placeholder={placeholder}
          defaultValue={defaultValue}
          value={val}
          isDisabled={disabled}
          styles={{
            option: (base, state) => ({
              ...base,
              backgroundColor: state.isFocused ? '#EFF6FF' : '',
            }),
            groupHeading: (base) => ({
              ...base,
              color: '#0F172A',
              fontWeight: 'bold',
              fontSize: '14px',
              cursor: 'pointer',
            }),
          }}
          classNames={hardClassName}
        />
      )}

      {touched && error && <FormError>{error}</FormError>}
    </div>
  )
}
