import {FC, MouseEventHandler, ReactNode} from 'react'
import {GModal} from '../../Libs'
import ModalFooter from './ModalFooter'
import ModalHeader from './ModalHeader'
import { FormikProps } from 'formik'

interface BasicModalProps {
  show: boolean
  handleSuccess: MouseEventHandler
  handleClose: MouseEventHandler
  header: string
  children?: ReactNode
  positiveLabel?: string
  negativeLabel?: string
  disablePositive?: boolean
  countingNote?: string
  footNote?: string
  headerWeight?: 'bold' | 'medium' | 'normal'
  size?: 'xxl'|'xl' | 'lg' | 'md' | 'sm'
  loading?: boolean
  formik?: FormikProps<any>
  type?: any
}

const BasicModal: FC<BasicModalProps> = ({
  show,
  handleSuccess,
  handleClose,
  header,
  positiveLabel,
  negativeLabel,
  disablePositive = false,
  size = 'md',
  headerWeight = 'medium',
  loading,
  footNote = '',
  countingNote = '',
  children
}) => {
  return (
    <GModal show={show} onHide={handleClose} size={size}>
      <ModalHeader header={header} handleClose={handleClose} titleWeight={headerWeight} />
      <div className='px-6 py-5 min-h-[100px] text-neutral-80 w-full'>{children}</div>
      <ModalFooter
        countingNote={countingNote}
        footNote={footNote}
        handleClose={handleClose}
        handleSuccess={handleSuccess}
        positiveLabel={positiveLabel}
        negativeLabel={negativeLabel}
        disablePositive={disablePositive}
        loading={loading}
      />
    </GModal>
  )
}

export default BasicModal
