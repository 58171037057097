const TierBenefitScreens = {
  TIER_BENEFIT: {
    PATH: '/tier-benefit',
    TITLE: 'Tier & Benefit',
    TITLE_ALT: 'Tier & Benefit',
    // PERMISSION: 'tier-benefit.get-list',
  },
  LIST_TIER: {
    PATH: '/tier-benefit/master-tier',
    HEADER_NAME: 'master tier',
    TITLE: 'Master Tier',
    TITLE_ALT: 'Master Tier',
    // PERMISSION: 'tier.get-list',
    // breadcrumbs: defaultBreadcrumbs,
  },
  ADD_TIER: {
    PATH: '/tier-benefit/master-tier/add',
    HEADER_NAME: 'Create Master Tier',
    TITLE: 'Create Master Tier',
    TITLE_ALT: 'Create Master Tier',
    // PERMISSION: 'tier.create',
    // breadcrumbs: defaultBreadcrumbs,
  },
  DETAIL_TIER: {
    PATH: '/tier-benefit/master-tier/detail/:id',
    HEADER_NAME: 'View Detail Master Tier',
    TITLE: 'View Detail Master Tier',
    TITLE_ALT: 'View Detail Master Tier',
    buildPath: (id: string) => `/tier-benefit/master-tier/detail/${id}`,
    // PERMISSION: 'tier.create',
    // breadcrumbs: defaultBreadcrumbs,
  },
  EDIT_TIER: {
    PATH: '/tier-benefit/master-tier/edit/:id',
    HEADER_NAME: 'Edit Master Tier',
    TITLE: 'Edit Master Tier',
    TITLE_ALT: 'Edit Master Tier',
    buildPath: (id: string) => `/tier-benefit/master-tier/edit/${id}`,
    // PERMISSION: 'tier.create',
    // breadcrumbs: defaultBreadcrumbs,
  },
  GAMIFICATION:{
    PATH: '/tier-benefit/gamification',
    TITLE: 'Gamification',
    TITLE_ALT: 'Gamification',
  },
  LIST_GAMIFICATION: {
    PATH: '/tier-benefit/gamification',
    HEADER_NAME: 'Gamification',
    TITLE: 'Gamification',
    TITLE_ALT: 'Gamification'
  },
  ADD_GAMIFICATION: {
    PATH: '/tier-benefit/gamification/add',
    HEADER_NAME: 'Create Gamification',
    TITLE: 'Create Gamification',
    TITLE_ALT: 'Create Gamification',
    // PERMISSION: 'tier.create',
    // breadcrumbs: defaultBreadcrumbs,
  },
  DETAIL_GAMIFICATION: {
    PATH: '/tier-benefit/gamification/detail/:id',
    HEADER_NAME: 'View Detail Gamification',
    TITLE: 'View Detail Gamification',
    TITLE_ALT: 'View Detail Gamification',
    buildPath: (id: string) => `/tier-benefit/gamification/detail/${id}`,
    // PERMISSION: 'tier.create',
    // breadcrumbs: defaultBreadcrumbs,
  },
  EDIT_GAMIFICATION: {
    PATH: '/tier-benefit/gamification/edit/:id',
    HEADER_NAME: 'Edit Gamification',
    TITLE: 'Edit Gamification',
    TITLE_ALT: 'Edit Gamification',
    buildPath: (id: string) => `/tier-benefit/gamification/edit/${id}`,
    // PERMISSION: 'tier.create',
    // breadcrumbs: defaultBreadcrumbs,
  },
}

export default TierBenefitScreens
