import { env } from './env-utils'

export const AppName = () => env.REACT_APP_THEME_NAME

export function getTitle(name?: string) {
  if (!name) return env.REACT_APP_THEME_NAME ?? ''
  return `${name} - ${env.REACT_APP_THEME_NAME}`
}

export function capitalizeFirstLetter(str?: string): string {
  if (!str) return '';

  const words = str.split(' ');
  const capitalizedWords = words.map(word => {
    if (word.toUpperCase() === 'ACTIVE') {
      return 'Active';
    } else {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    }
  });

  return capitalizedWords.join(' ');
}

const orderTypeNames: { [key: string]: string } = {
  HMD: "Home Delivery",
  PNP: "Pick and Pay",
  DRT: "Drive Thru",
  DIN: "Dine In",
  TKA: "Take Away",
};

export function formatOrderTypes(orderTypes: string | string[] | undefined): string {
  if (!orderTypes) {
    return '';
  }

  if (typeof orderTypes === 'string') {
    return orderTypeNames[orderTypes];
  }

  const formattedTypes = orderTypes.map(type => orderTypeNames[type]);

  return formattedTypes.join(", ");
}
