import axios from 'axios'
import { BaseResponse, Pagination, Response } from 'src/app/models/api.types'
import { BannerModel } from '../models/Banner.model'
import dayjs from 'dayjs'
import { SectionBannerModel } from '../models/SectionBanner.model'

// API URL
export const BANNER = '/admins/v1/banner'
export const SECTIONBANNER = '/admins/v1/section-banner'

// REACT QUERY KEY
export const LIST_BANNER_KEY = 'list-banner'
export const DETAIL_BANNER_KEY = 'detail-banner'
export const ACCESS_TEMPLATE = 'access-template'
export const DETAIL_SECTION_BANNER_KEY = 'detail-section-banner'

// Menu API
export const getListBanner = (params?: any, module: string = 'banner') => {
  const url = module === 'section-banner' ? SECTIONBANNER : BANNER;
  return axios.get<BaseResponse<Pagination<BannerModel[]>>>(url, {
    params,
  });
}

export const getDetailBanner = (id?: any, module: string = 'banner') => {
  const url = module === 'section-banner' ? SECTIONBANNER : BANNER;
  return axios.get<BaseResponse<Response<BannerModel>>>(`${url}/${id}`)
}

export const saveBanner = (data?: BannerModel | SectionBannerModel, module: string = 'banner', id?: string) => {
  const url = module === 'section-banner' ? SECTIONBANNER : BANNER;
  const start_date = dayjs(data?.start_date).format('YYYY-MM-DD')
  const end_date = dayjs(data?.end_date).format('YYYY-MM-DD')
  data = {...data, start_date, end_date}
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };
  const transformedData = module === 'section-banner' ? JSON.stringify(data) : data;

  if (id) return axios.put(`${url}/${id}`, transformedData, config)

  return axios.post(url, transformedData, config)
}

export const deleteBanner = (id?: string, module: string = 'banner') => {
  const url = module === 'section-banner' ? SECTIONBANNER : BANNER;
  return axios.delete(`${url}/${id}`)
}

export const updateFeaturedBanner = (data?: any, module: string = 'banner') => {
  const url = module === 'section-banner' ? SECTIONBANNER : BANNER;
  return axios.put(`${url}/status-update`, data)
}

export const bulkChooseBanner = (data: any, module: string = 'banner') => {
  const url = module === 'section-banner' ? SECTIONBANNER : BANNER;
  return axios.put(`${url}/bulk-update`, data)
}

export const saveSequenceBanner = (data?: any, module: string = 'banner') => {
  const url = module === 'section-banner' ? SECTIONBANNER : BANNER;
  if(data) return axios.put(`${url}/sequence-update`, data)
  return false
}
