import { FC } from 'react'
import { formatDateWib } from 'src/app/utils/input-utils'

const HandleDateTime: FC<{ data?: any }> = ({ data }) => {
  const date = formatDateWib(data, true).split(' ')
  return (
    <div className="flex flex-col">
      <div>
        {date[0]}
      </div>
      <div className="text-fs-9">
        {date[1]}
      </div>
    </div>
  )
}

export default HandleDateTime
