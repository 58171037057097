import { useEffect, useRef } from "react";
import { ActionMeta } from "react-select";
import { PaginateModel } from "src/app/models/api.types";
import { hasArray, safeArray } from "src/app/utils/array-utils";
import { useMultiSelectContext } from "./MultiSelectAll.context";
import { FormMultiSelectAllProps, MultiSelectAllOption, SelectAsyncAdditionalPayload } from "./MultiSelectAll.types";

const defaultSelectAllOption = {
  label: 'Select All',
  value: '__all'
};

export function useMultiSelectAllHook({
  withSelectAllOption = false,
  selectAllOption = defaultSelectAllOption,
  selectedValue = [],
  placeholderValue,
  onChangeValue,
  selectOption,
  fetchApi,
  options,
  payload
}: FormMultiSelectAllProps<any>) {

  const optionsMaster = useRef<MultiSelectAllOption[]>([]);
  const hasFetchApi = typeof fetchApi === 'function';
  const context = useMultiSelectContext();

  // trigger while value is already filled
  useEffect(
    () => {
      if (context.totalOptions < 1 && hasArray(selectedValue)) {
        getLoadOptions('', undefined, { page: 1 })
          .then(() => { })
          .catch(() => { });
      }
    },
    [
      hasArray(selectedValue),
      context.totalOptions
    ]
  );

  const getInputValue = () => {
    if (!selectedValue || selectedValue?.length < 1) {
      return '';
    }
    const optionLen = context.totalOptions;
    const valuesLen = selectedValue.length;

    if (!hasFetchApi) {
      const valuesLen = selectedValue.length;
      return placeholderValue?.(valuesLen, valuesLen === options?.length);
    }

    if (withSelectAllOption) {
      const hasSelectAll = selectedValue.some((_) => _.value === selectAllOption.value);
      const optionSelectedLength = hasSelectAll ? optionLen - valuesLen : valuesLen;
      const isSelectedAll = hasSelectAll && selectedValue.length < 2;
      return placeholderValue?.(optionSelectedLength, isSelectedAll);
    }
    const optionSelectedLength = selectedValue.length;
    return placeholderValue?.(optionSelectedLength, valuesLen === optionLen);
  };

  const handleChange = (values: MultiSelectAllOption[], actionMeta: ActionMeta<any>) => {

    // fired while Select All is toggled
    if (actionMeta.option?.value === selectAllOption.value) {
      if (actionMeta.action === 'select-option') {
        onChangeValue?.([selectAllOption], actionMeta);
        return;
      }
      if (actionMeta.action === 'deselect-option') {
        const _values = values.length > 0 ? [selectAllOption] : []
        onChangeValue?.(_values, actionMeta);
        return;
      }
    };

    // fired while Section is toggled
    if (!!actionMeta.option?.section) {
      const filteredValues = values.filter((_) => !_.section);
      const sectionValues = values.filter((_) => _.sectionGroup === actionMeta.option?.section);
      const isSelectedAll = sectionValues.length === actionMeta.option.options.length;
      if (isSelectedAll) {
        const _values = filteredValues.filter((_) => !safeArray(actionMeta.option.options).some((__) => _.value === __.value));
        onChangeValue?.(_values, actionMeta);
      } else {
        const sectionOption = safeArray(actionMeta.option.options).filter((_) => !values.some((__) => _.value === __.value));
        const _values = [...filteredValues, ...sectionOption];
        onChangeValue?.(_values, actionMeta);
      }
      return;
    }

    // fired while Checkbox is toggled
    onChangeValue?.(values, actionMeta);
  };

  const getLoadOptions = async (search: string, _: unknown, additional: SelectAsyncAdditionalPayload) => {
    try {

      if (!hasFetchApi) {
        return ({
          hasMore: false,
          options: []
        });
      }

      const response = await fetchApi?.({ ...additional, search, ...payload });
      const {
        pagination,
        content,
        meta
      } = response?.data?.response_output?.list || {};
      const {
        total_pages = 1,
        page = 1
      } = pagination ?? {} as PaginateModel;
      const options = safeArray(content).map((_: any, __: number) => ({ ...selectOption?.(_, __) }));
      const totalOptions = meta?.total_outlets ?? pagination?.total_rows;
      context.setTotalOptions(totalOptions);

      if (withSelectAllOption && !search && page < 2) {
        options.unshift(selectAllOption);
      }
      return {
        hasMore: additional.page < total_pages,
        additional: { ...additional, page: additional.page + 1 },
        options
      };

    } catch (error) {
      return ({
        hasMore: false,
        options: []
      });
    }
  };

  const getReducedOptions = (prevData: MultiSelectAllOption[], loadedData: MultiSelectAllOption[]) => {
    optionsMaster.current = [...prevData, ...loadedData];
    if (withSelectAllOption && !optionsMaster.current.some((_) => _.value === selectAllOption.value)) {
      optionsMaster.current.unshift(selectAllOption);
    }
    return optionsMaster.current;
  };

  return {
    action: {
      getReducedOptions,
      getLoadOptions,
      getInputValue,
      handleChange
    },
    state: {
      hasFetchApi
    }
  };
}
