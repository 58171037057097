import { useCallback } from 'react';
import { PromoBannerQuery } from '../models/PromoBanner.model';
import { useMutation } from '@tanstack/react-query';

interface UseBannerDeletionProps {
  selected: any
  moduleName?: string
  deleteBanner: any
  addPageToasts: any
  setDeleteModal: (isOpen: boolean) => void
  setPage: (page: number) => void
  refetch: () => void
  errorTemp: (error: any) => void
}

interface UseBannerFiltersProps {
  formik: any
  setQuery: (query: (prev: PromoBannerQuery) => PromoBannerQuery) => void
  setIsShow: (isShow: boolean) => void
  setIsShowCalendar: (isShowCalendar: boolean) => void
  setFilterCount: (count: number) => void
  tempFilterCount: number
}

export const useBannerFilters = ({
  formik,
  setQuery,
  setIsShow,
  setIsShowCalendar,
  setFilterCount,
  tempFilterCount
}: UseBannerFiltersProps) => {
  const applyFilterFn = useCallback(
    (params: PromoBannerQuery) => {
      const { status, statuses, platform, start_date, end_date } = params;
      setIsShow(false);
      setFilterCount(tempFilterCount);
      setIsShowCalendar(false);
      const statusFilter = status || [];
      const statusesFilter = statuses || [];
      const hasBlankStatus = statusFilter.includes('');
      const statusList = hasBlankStatus ? [] : statusFilter;

      const hasBlankStatuses = statusesFilter.includes('');
      const statusesList = hasBlankStatuses ? [] : statusesFilter;

      const hasBlankPlatform = platform?.includes('');
      const platformList = hasBlankPlatform ? [] : platform;

      setQuery((prev) => ({ 
        ...prev, 
        status: statusList,
        statuses: statusesList,
        platform: platformList, 
        start_date, 
        end_date 
      }));
    },
    [setQuery, setIsShow, setIsShowCalendar, setFilterCount, tempFilterCount]
  );

  const resetFilterFn = useCallback(() => {
    formik.resetForm();
    setFilterCount(0);
    setIsShow(false);
    setIsShowCalendar(false);
    setQuery((prev) => ({
      ...prev,
      status: formik.initialValues.status,
      statuses: formik.initialValues.statuses,
      start_date: formik.initialValues.start_date,
      end_date: formik.initialValues.end_date
    }));
  }, [setQuery, setIsShow, setIsShowCalendar, setFilterCount, formik]);

  return { applyFilterFn, resetFilterFn };
};

export const useBannerDeletion = ({
  selected,
  moduleName = 'banner',
  deleteBanner,
  addPageToasts,
  setDeleteModal,
  setPage,
  refetch,
  errorTemp
}: UseBannerDeletionProps): { deleteFn: any } => {
  const deleteFn = useMutation({
    mutationFn: () => deleteBanner(selected.id, moduleName),
    onSuccess: () => {
      addPageToasts({ scheme: 'success', text: 'Delete banner success' });
      setDeleteModal(false);
      setPage(1);
      refetch();
    },
    onError: (e) => errorTemp(e),
  });

  return { deleteFn };
};

